<template>
  <div>
    <div class="row mb-2">
      <div class="col-sm-4">
<!--        <router-link to="/rentals/add-car" class="btn btn-danger mb-2"-->
<!--        ><i class="mdi mdi-plus-circle mr-1"></i> Add New Car </router-link-->
<!--        >-->
      </div>
      <div class="col-sm-8">
        <div class="float-sm-right">
          <router-link to="/rentals/view-controls" class="btn btn-primary mb-2"
          ><i class="fas fa-eye mr-1"></i> View configured controls </router-link
          >
        </div>
      </div>
      <!-- end col-->
    </div>

    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Add Arrival / Departure Control</h4>
        <form action="" @submit.prevent="handleSubmit">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="label">Set control label <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="label"
                       placeholder="Enter label as it will appear on mobile app"
                       :class="{'is-invalid' : form.submitted && $v.form.label.$error}"
                       v-model="form.label"
                >
                <div class="invalid-feedback" v-if="form.submitted && $v.form.label.$error">This field is required</div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="subLabel">Set control sub label (optional)</label>
                <input type="text" class="form-control" id="subLabel"
                       placeholder="Enter sub label as it will appear on mobile app"
                       v-model="form.subLabel"
                >
              </div>
            </div>
          </div>
          <div class="row"  v-if="false">
            <div class="col-md-12">
              <div class="form-group">
                <label for="">Control type</label>
                <select name="" id="" class="form-control"
                        v-model="form.controlType"
                        :class="{'is-invalid' : form.submitted && $v.form.controlType.$error}">
                  <option value="text">Text</option>
                  <option value="checkbox">Checkbox</option>
                </select>
                <div class="invalid-feedback" v-if="form.submitted && $v.form.controlType.$error">This field is required</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="useForArrival">Use as arrival control <span class="text-danger">*</span></label>
                <select name="" id="useForArrival" class="form-control"
                        :class="{'is-invalid' : form.submitted && $v.form.useForArrival.$error}"
                        v-model="form.useForArrival"
                >
                  <option value="">-- select one -- </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                <div class="invalid-feedback" v-if="form.submitted && $v.form.useForArrival.$error">This field is required</div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="useForDeparture">Use as departure control <span class="text-danger">*</span></label>
                <select name="" id="useForDeparture" class="form-control"
                        v-model="form.useForDeparture"
                        :class="{'is-invalid' : form.submitted && $v.form.useForDeparture.$error}"
                >
                  <option value="">-- select one -- </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                <div class="invalid-feedback" v-if="form.submitted && $v.form.useForDeparture.$error">This field is required</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="requiredForArrival">Required for arrival control <span class="text-danger">*</span></label>
                <select name="" id="requiredForArrival" class="form-control"
                        v-model="form.requiredForArrival"
                        :class="{'is-invalid' : form.submitted && $v.form.requiredForArrival.$error}"
                >
                  <option value="">-- select one -- </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                <div class="invalid-feedback" v-if="form.submitted && $v.form.requiredForArrival.$error">This field is required</div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="requiredForDeparture">Required for departure control <span class="text-danger">*</span></label>
                <select name="" id="requiredForDeparture" class="form-control"
                        v-model="form.requiredForDeparture"
                        :class="{'is-invalid' : form.submitted && $v.form.requiredForDeparture.$error}"
                >
                  <option value="">-- select one -- </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                <div class="invalid-feedback" v-if="form.submitted && $v.form.requiredForDeparture.$error">This field is required</div>
              </div>
            </div>
          </div>

          <hr>
          <div class="form-group">
            <div class="text-center">
              <button type="submit" class="btn btn-danger">Submit</button>
            </div>
          </div>

        </form>

      </div>
    </div>

  </div>

</template>

<script>
import {required} from "vuelidate/lib/validators";

export default {
  name: "ViewRentalControls",
  validations: {
    form: {
      label: {
        required
      },
      useForDeparture: {
        required
      },
      useForArrival: {
        required
      },
      requiredForDeparture: {
        required
      },
      requiredForArrival: {
        required
      }
    }
  },
  data(){
    return {
      form: {
        submitted: false,
        label: '',
        subLabel: '',
        requiredForDeparture: '',
        requiredForArrival: '',
        useForDeparture: '',
        useForArrival: ''
      }
    }
  },
  methods: {
    handleSubmit(){
      this.form.submitted = true;
      this.$v.$touch();

      if(this.$v.$invalid)
        return;


      const formData = {
        'label': this.form.label,
        'sub_label': this.form.subLabel,
        'control_type': 'text',
        'required_for_departure': this.form.requiredForDeparture,
        'required_for_arrival': this.form.requiredForArrival,
        'use_for_departure': this.form.useForDeparture,
        'use_for_arrival': this.form.useForArrival,
      };
      
      this.$store.dispatch('addRentalControls', formData).then(() => {
            this.form.submitted= false;
            this.form.label= '';
            this.form.subLabel= '';
            this.form.controlType= '';
            this.form.requiredForDeparture= ''
            this.form.requiredForArrival= ''
            this.form.useForDeparture= ''
            this.form.useForArrival= ''
      })

    }
  }
}
</script>

<style scoped>

</style>